import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Container } from '../components/Container'
import { PracticeAreaBox } from '../components/PracticeAreaBox'

import { PracticeArea } from '../interfaces'

const practiceAreasContainer = css`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`

interface Props {
  data: {
    practiceAreas: {
      nodes: Array<PracticeArea>
    }
  }
}

function PracticeAreasPage(props: Props) {
  const practiceAreas = props.data.practiceAreas.nodes

  return (
    <Layout spaceTop={true}>
      <SEO title="Verksamhetsområden" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Container>
        <section css={practiceAreasContainer}>
          {practiceAreas.map((area, idx) => (
            <PracticeAreaBox
              title={area.title}
              preamble={area.preamble}
              backgroundColor={area.associatedColor?.hex}
              slug={area.slug}
              key={idx}
            />
          ))}
        </section>
      </Container>
    </Layout>
  )
}

export default PracticeAreasPage

export const query = graphql`
  query PracticeAreasPage {
    practiceAreas: allDatoCmsPracticeArea {
      nodes {
        slug
        title
        preamble
        associatedColor {
          hex
        }
      }
    }
  }
`
